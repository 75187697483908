.image-carousel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem; /* Ajustez l'espacement entre les images selon vos besoins */
}

.image {

  object-fit: cover;
  border-radius: 1rem; /* Ajustez le rayon de la bordure selon vos besoins */
  transform: scale(1);
  transition: transform 1s ease-in-out;
}

.image.visible {
  transform: scale(1.2); /* Ajustez le facteur d'échelle selon vos besoins */
}