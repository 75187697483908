.news-feed-container {
    overflow: hidden;
  }
  
  .news-feed {
    display: flex;
    animation: scrollAnimation 25s linear infinite; /* Ajustez la durée selon vos besoins */
  }
  
  .news-item {
     flex: 0 0 35%; 
  } 

  .visible {
    opacity: 1;
  }
  
  .news-image {
    width: 100%;
    height: auto;
  }
  
  .news-content {
    padding: 20px;
    background-color: white;
  }
  
  @keyframes scrollAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  